import './LockHistory.scss';
import { UserLock } from '../../containers/Uzd';
import { getFullDisplayBalance } from '../../utils/formatbalance';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface TransactionHistoryProps {
    items: Array<UserLock>;
    onPageEnd?: Function;
    emptyText: string;
    columns: Array<string>;
    onUnlockClick?: Function;
    onUnlockWithPenaltyClick?: Function;
    hideUnlockWithPenalty?: boolean;
    unlockBtnText?: string;
    icon?: string;
    showWithdraw?: boolean;
    onWithdrawClick?: Function;
}

export const LockHistory: React.FC<TransactionHistoryProps & React.HTMLProps<HTMLDivElement>> = ({
    className,
    items,
    emptyText,
    onUnlockClick,
    onUnlockWithPenaltyClick,
    columns,
    hideUnlockWithPenalty = true,
    unlockBtnText,
    icon,
    showWithdraw,
    onWithdrawClick,
}) => {
    const currentTs = new Date().getTime() / 1000;

    return (
        <div className={`LockHistory ${className}`}>
            <div className="d-flex header">
                {columns.map((column: string, key: number) => (
                    <div key={key}>{column}</div>
                ))}
            </div>
            <div className="LockHistory__List">
                {items &&
                    items.map((item, index) => (
                        <div className={`LockHistory__List-Item d-flex border-chameleon ${!Number(item.amount) || !Number(item.unlockTs) ? 'd-none' : ''}`} key={index}>
                            <div className="amount font-bold text-chameleon d-flex align-items-center">
                                {getFullDisplayBalance(new BigNumber(item.amount.toString()))}
                                {icon && <img src={icon} alt="" width="20" className="ms-2" />}
                            </div>
                            <div className={`lock-period font-bold text-chameleon`}>
                                { item.unlocked && <span>unlocked</span> }
                                { !item.unlocked && moment.duration(currentTs - Number(item.unlockTs), 'seconds').humanize() }
                            </div>
                            <div className="actions d-flex gap-2 align-items-center">
                                <OverlayTrigger overlay={<Tooltip>{item.lockRelease ? `Unlock date: ${item.lockRelease.toISOString()}` : ''}</Tooltip>}>
                                    <button
                                        className={`lock-btn zun-button ${!item.unlocked ? 'disabled' : ''}`}
                                        onClick={() => {
                                            if (onUnlockClick) {
                                                onUnlockClick(index);
                                            }
                                        }}
                                    >
                                        {unlockBtnText ? unlockBtnText : 'Unlock'}
                                    </button>
                                </OverlayTrigger>
                                {
                                    showWithdraw && 
                                        <button
                                            className={`withdraw-btn zun-button ${!item.canWithdraw ? 'disabled' : ''}`}
                                            onClick={() => {
                                                if (onWithdrawClick) {
                                                    onWithdrawClick(index);
                                                }
                                            }}
                                        >
                                            Withdraw
                                        </button>
                                }
                                {
                                    !item.unlocked && !hideUnlockWithPenalty && (
                                        <div
                                            className="unlock-with-penalty text-chameleon"
                                            onClick={() => {
                                                if (onUnlockWithPenaltyClick) {
                                                    onUnlockWithPenaltyClick(index);
                                                }
                                            }}
                                        >Unlock with 15% Penalty Fee</div>
                                    )
                                }
                            </div>
                        </div>
                    ))}
                {!items.length && (
                    <div className="text-center empty">
                        <div className="">{emptyText}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
